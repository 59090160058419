<script setup lang="ts">
import { ApiClientError } from "@shopware/api-client";
import type { ApiError } from "@shopware/api-client";
import Input from "@/components/atoms/Input";
import Button from "~/components/atoms/Button";
import { useVuelidate } from "@vuelidate/core";
import { customValidators } from "@/i18n/utils/i18n-validators";
import { computed } from "vue";
import { fieldNames } from "@/constants/fieldNames";

const { required, minLength, email } = customValidators();
const { isLoggedIn, login } = useUser();
const { t } = useI18n();
const { refreshSessionContext } = useSessionContext();
const { mergeWishlistProducts, getWishlistProducts } = useWishlist();
const { pushSuccess } = useNotifications();
const router = useRouter();
const loginErrors = ref<string[]>([]);

const emits = defineEmits<{
  (e: "success"): void;
  (e: "close"): void;
}>();

const props = withDefaults(
  defineProps<{
    loginHeadline?: string;
    isCheckout?: boolean;
  }>(),
  {
    loginHeadline: "account.signInLabel",
  },
);

const formData = ref({
  username: "",
  password: "",
  remember: true,
});

const loginFormRules = () => {
  return {
    username: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
    },
    remember: {},
  };
};

const v$ = useVuelidate(loginFormRules(), formData);

const { resolveApiErrors } = useApiErrorsResolver("account_login");

const invokeLogin = async (): Promise<void> => {
  loginErrors.value = [];
  try {
    v$.value.$validate();
    const hasFormErrors = v$.value.$errors.length > 0;
    if (!hasFormErrors) {
      // TODO: remove this line once the https://github.com/shopware/frontends/issues/112 issue is fixed
      await refreshSessionContext();
      await login(formData.value);
      emits("success");
      pushSuccess(t("account.messages.loggedInSuccess"));
      emits("close");
      getWishlistProducts();
      mergeWishlistProducts();
    }
  } catch (error) {
    if (error instanceof ApiClientError || error?.details?.errors) {
      loginErrors.value = resolveApiErrors(error.details.errors as ApiError[]);
    }
  }
};

const computedLoginErrors = computed(() => {
  if (loginErrors.value.length === 0) return "";
  return loginErrors.value?.[0];
});

const emailImputElement = ref();
useFocus(emailImputElement, { initialValue: true });

if (props?.isCheckout && isLoggedIn.value) {
  setTimeout(() => {
    emits("success");
  }, 3000);
}
</script>
<template>
  <div
    id="modal-headline"
    role="form"
    title="Login form"
    aria-label="Login form"
    class="max-w-screen-3xl px-4 md:px-6 3xl:px-12 min-h-[31.25rem] flex items-center md:pt-14"
  >
    <div
      v-if="!isLoggedIn"
      class="md:pt-14 md:pb-[17.5rem] md:flex md:flex-row md:gap-[8.875rem] w-full"
    >
      <div
        class="signin-section py-6 md:py-0 max-w-full md:max-w-[35.3125rem] w-full"
      >
        <h2 class="text-m-2xl font-medium md:text-2xl">
          {{ $t(loginHeadline) }}
        </h2>
        <form class="mt-6 md:mt-10" @submit.prevent="invokeLogin">
          <input
            v-model="formData.remember"
            type="hidden"
            name="remember"
            data-testid="login-remember-input"
          />
          <div>
            <slot name="error">
              <div
                v-if="computedLoginErrors"
                class="text-sm text-error-red mb-4"
              >
                {{ computedLoginErrors }}
              </div>
            </slot>

            <div>
              <Input
                v-model="formData.username"
                :label="$t('form.email')"
                :aria-label="$t('form.email')"
                :required="true"
                type="email"
                :id="fieldNames['email']"
                :name="fieldNames['email']"
                data-testid="login-email-input"
                :is-error="v$.username.$errors?.length > 0"
                :support-text="v$.username.$errors?.[0]?.$message as string"
                @blur="v$.username.$touch()"
              />
            </div>

            <div class="mt-4">
              <Input
                v-model="formData.password"
                :label="$t('form.password')"
                :aria-label="$t('form.password')"
                :required="true"
                type="password"
                :id="fieldNames['currentPassword']"
                :name="fieldNames['currentPassword']"
                data-testid="login-password-input"
                :is-error="v$.password.$errors?.length > 0"
                :support-text="v$.password.$errors?.[0]?.$message as string"
                @blur="v$.password.$touch()"
              />
            </div>
          </div>

          <div>
            <Button
              type="submit"
              data-testid="login-submit-button"
              class="mt-6 md:mt-10 w-full font-medium"
            >
              {{ $t("account.signIn") }}
            </Button>
          </div>

          <slot :data="formData" />
        </form>
      </div>

      <div
        class="signup-section py-6 md:py-0 md:max-w-[35.3125rem] w-full flex-col"
      >
        <slot name="action"></slot>
      </div>
    </div>

    <div class="w-full flex items-center justify-center" v-else>
      <h2 class="text-m-2xl font-medium md:text-2xl">
        {{ $t("account.loggedInInfo") }}
      </h2>
    </div>
  </div>
</template>
